<template>
  <div
    class="btn-scroll-to-top"
  >
    <!-- We have wrapper because ripple effect give position relative to this absolute positioned btn -->
    <!-- Hence due to that our btn get lost -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="btn-icon d-flex align-items-center"
      @click="scrollToTop"
    >
      <feather-icon
      icon="MessageCircleIcon"
      size="32"
      class="me-2"
      />
      <span>Chat Support</span>
    </b-button>
  </div>
</template>

<script>
import { useWindowScroll } from '@vueuse/core'
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
  setup() {
    const { y } = useWindowScroll()

    const scrollToTop = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent) || window.innerWidth <= 768;

      if (isMobile) {
          window.open("https://m.me/fastproxy.vip", "_blank");
      } else {
          window.open("https://www.facebook.com/messages/t/fastproxy.vip", "_blank");
      }
    }

    const goSupportPage = () => {
     
    }

    return { y, scrollToTop }
  },

}
</script>

<style lang="scss" scoped>
.btn-scroll-to-top {
  position: fixed;
    bottom: 5%;
    right: 30px;
    z-index: 99;

    opacity: 1;
    // transform: translateY(100px);
    transition: all .5s ease;

    &.show {
      opacity: 1;
      // transform: translateY(0)
    }
}
</style>
